import { render, staticRenderFns } from "./WapWXCW03Setting.vue?vue&type=template&id=95336c14&scoped=true&"
import script from "./WapWXCW03Setting.vue?vue&type=script&lang=js&"
export * from "./WapWXCW03Setting.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95336c14",
  null
  
)

export default component.exports