<template>
    <div>
        <van-field clickable @click="show_JieKouSelect = true" v-model="device.jieKouCompanyName" readonly label="接口配置">
        </van-field>
        <el-row>
            <el-col :span="12">
                <van-button block type="info" @click="saveCompany">保存接口</van-button>
            </el-col>
            <el-col :span="12">
                <van-button block type="danger" @click="jieBang">删除接口</van-button>
            </el-col></el-row>

        <van-cell-group style="margin-top: 50px;" v-if="device.deviceType == 'WXCW80'">
            <van-cell title="设备设置" />
            <van-field label="背光时长(分钟)" label-width="120px" v-model="WXCW80_Config.lightTime" placeholder="请输入背光时长" @change.native="saveModel" input-align="right"></van-field>
            <van-field label="采样间隔(分钟)" label-width="120px" v-model="WXCW80_Config.intervalTime" placeholder="请输入采样间隔" @change.native="saveModel" input-align="right"></van-field>
            <van-cell is-link :closeable="true" title="开机升级" @click="show = true">{{ WXCW80_Config.ifUpgrade ? '是' : '否' }}</van-cell>
            <van-popup v-model="show" position="bottom" :style="{ height: '350px' }" @open="open">
                <van-password-input style="margin-top: 10px;" :value="pwd" :focused="showKeyboard" :length="4" @focus="showKeyboard = true" info="密码为 4 位数字" />
                <van-number-keyboard v-model="pwd" :show="showKeyboard" @blur="showKeyboard = true" />
            </van-popup>
            <van-action-sheet v-model="actionShow" :actions="actions" @select="onSelect" />
        </van-cell-group>
        <van-popup v-model="show_JieKouSelect" position="bottom">
            <van-picker title="标题" show-toolbar :columns="columns" @confirm="onConfirm" :default-index="cmp_default_index" @cancel="show_JieKouSelect = false"></van-picker>
        </van-popup>
    </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);
export default {
    props: ["serialNumber"],
    data() {
        return {
            pwd: "",
            showKeyboard: true,
            WXCW80_Config: {},
            show_JieKouSelect: false,
            columns: ["光环锐通", "高新市政", "高新市政1", "道亨", "匠心筑福", "青岛一开", "金蟾软件", "山东泽峻"],
            device: { jieKouCompanyName: "" },
            show: false,
            actionShow: false,
            actions: [{ name: "是" }, { name: "否" }],
        };
    },
    watch: {
        pwd(pwd) {
            let that = this;
            if (pwd.length === 4 && pwd == "8672") {
                that.actionShow = true;
                that.show = false;
            } else if (pwd.length === 4 && pwd !== "8672") {
                that.$notify({ type: "danger", message: "密码错误" });
                that.show = false;
            }
        },
    },
    computed: {
        cmp_default_index() {
            let that = this;
            return that.columns.indexOf(that.device.jieKouCompanyName);
        },
    },
    methods: {
        open() {
            let that = this;
            that.pwd = "";
        },
        onSelect(item) {
            let that = this;
            if (item.name === "是") {
                that.WXCW80_Config.ifUpgrade = true;
            } else if (item.name === "否") {
                that.WXCW80_Config.ifUpgrade = false;
            }
            that.saveModel();
            that.show = false;
            that.actionShow = false;
        },
        saveModel() {
            let that = this;
            that.axios
                .post("WXCW80/UpdateConfig", that.WXCW80_Config)
                .then(function (response) {
                    if (response.data.code === 101) {
                        that.$notify({
                            type: "success",
                            message: "保存成功，请重启设备",
                        });
                    }
                });
        },
        setup() {
            let that = this;
            //获取选择的接口公司名称
            that.axios
                .post("WXCW80/GetConfig1", { serialNumber: that.serialNumber })
                .then(function (response) {
                    that.WXCW80_Config = response.data.data;
                });
        },
        saveCompany() {
            let that = this;
            that.axios.post("Base_Device/UpdateModel", that.device).then(function () {
                that.$notify({ type: "success", message: "保存接口成功" });
            });
        },
        onConfirm(v) {
            let that = this;
            that.show_JieKouSelect = false;
            that.device.jieKouCompanyName = v;
        },
        jieBang() {
            let that = this;
            that.$dialog
                .confirm({ message: "确定要删除接口吗？" })
                .then(function () {
                    that.axios
                        .post("WXCW_JieKou/JieBang", { onlyText: that.serialNumber })
                        .then(function (response) {
                            that.device = response.data.data;
                            that.$notify({ type: "success", message: "删除接口成功" });
                        });
                })
                .catch(function () { });
        },
    },
    mounted() {
        let that = this;
        that.setup();
        //获取选择的接口公司名称
        that.axios
            .post("Base_Device/GetModelBySerialNumber", {
                serialNumber: that.serialNumber,
            })
            .then(function (response) {
                that.device = response.data.data;
            });
    },
};
</script>
<style scoped></style>